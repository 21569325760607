<template>
    <div class="finish-wrap">
        <div class="logo">
            <img src="../assets/images/bentley_logo_a-c1@2x.png"  alt="" />
        </div>
        <div class="video-box">
            <img :src="`${aliyunOssUrl}/base/training/hk_gm_title.jpg`"  alt="" />
        </div>
        <div class="quiz-box" @click="clickPaneQuiz">
            <div class="quiz-left">
                <span class="span1">總經理領導力需求</span>
                <span class="span2">問卷調研</span>
            </div>
            <div class="quiz-right">
                <span class="quiz-arrow"><van-icon name="arrow" /></span>
            </div>
        </div>
        <div v-if="!isAppointment" class="quiz-box" @click="clickPaneInterview">
            <div class="quiz-left">
                <span class="span1">訓練營測評</span>
                <span class="span2">預約</span>
            </div>
            <div class="quiz-right">
                <span class="quiz-arrow"><van-icon name="arrow" /></span>
            </div>
        </div>
        <div v-if="isAppointment" class="quiz-box">
            <div class="quiz-row">
                <span class="span1">訓練營測評</span>
                <span class="span2">預約</span>
                <span class="span3">時間： {{strAppointmentDate}} {{strAppointmentTime}}</span>
                <span v-if="classUrl" class="span3">鏈接：<span class="span4" @click="copyUrl()">點擊複製</span></span>
                <div class="quiz-btn">
                <div :class="['btn', {'color': !canAppoiintment()}]" @click="handleCancel()">取消</div>
                </div>
            </div>
        </div>
        <div class="quiz-box" @click="clickSignUp">
            <div class="quiz-left">
                <span class="span1">總經理訓練營</span>
                <span class="span2">活動報名</span>
            </div>
            <div class="quiz-right">
                <span class="quiz-arrow"><van-icon name="arrow" /></span>
            </div>
        </div>
        <div class="quiz-box" @click="clickScoreRanking">
            <div class="quiz-left">
                <span class="span1">總經理訓練營</span>
                <span class="span2">積分排行榜</span>
            </div>
            <div class="quiz-right">
                <span class="quiz-arrow"><van-icon name="arrow" /></span>
            </div>
        </div>
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker @cancel="() => showPicker = false" @confirm="onConfirm" show-toolbar title="預約時間" :columns="columns"/>
        </van-popup>
    </div>
</template>
<script>
import {Landing} from '@/network'
import { Icon, Picker, Popup, Toast, Dialog } from 'vant';
import moment from 'moment';
import { groupBy } from 'lodash';

export default {
    data() {
        return {
            hubId: sessionStorage.getItem('hubId') || null,
            phone: sessionStorage.getItem('phone') || null,
            userInfo: {},
            showPicker: false,
            strAppointmentDate: null,
            strAppointmentTime: null,
            classUrl: null,
            columns: [],
            groupList: {},
            isAppointment: false,
            quizStatus: null,
            startDate: '2022/05/27',
            endDate: '2022/06/02',
            aliyunOssUrl: process.env.VUE_APP_ALIYUN_OSS_URL,
        }
    },
    async created() {
        this.userInfo = await Landing.getByHubId({hubId: this.hubId})
        // if (this.userInfo && this.userInfo.code == 401) {
        //     this.$router.replace({name: 'Login'})
        // }
        const data = await Landing.getAppointmentByHubId({hubId: this.hubId})
        if (data) {
            this.isAppointment = true
            this.strAppointmentDate = moment(data.appointmentDate).format('YYYY年MM月DD日')
            this.strAppointmentTime = data.appointmentTime
            this.classUrl = data.classUrl
        }
        // this.quizStatus = await Landing.getQuizStatus({hubId: this.hubId, local: 'hk'})
    },
    components: {
        VanIcon: Icon,
        vanPicker: Picker,
        vanPopup: Popup,
    },
    methods: {
        clickPaneQuiz() {
            if (this.canAppoiintment()) {
                console.log('開始進入問卷')
                window.location.href = process.env.VUE_APP_GM_QUIZ_URL + '&hubId=' + this.hubId + '&_locale=zh_HK'
            } else {
                Dialog.alert({
                    width: '95%',
                    allowHtml: true,
                    className: 'dialog-alert-font-class2',
                    messageAlign: 'left',
                    title: '非常抱歉',
                    message: '總經理領導力需求問卷調研僅在5月27日至6月1日開放。如有任何問題，請您與培訓助理進行聯繫：<br>Xiaoshuang Liu<br>xsliu@gpstrategies.com'
                })
            }
        },
        canAppoiintment() {
            let res = false
            let startDate = new Date(this.startDate)
            let endDate = new Date(this.endDate)
            let now = new Date()
            if (now >= startDate && now < endDate) {
                res = true
            }
            return res
        },
        canSignUp() {
            let res = false
            let startDate = new Date('2022/07/22')
            let endDate = new Date('2022/07/27')
            let now = new Date()
            if (now >= startDate && now < endDate) {
                res = true
            }
            return res
        },
        clickScoreRanking() {
            console.warn('开始进入积分排行榜')
            window.location.href = process.env.VUE_APP_GM_SCORE_RANKING_URL + '&hubId=' + this.hubId + '&_locale=zh_HK'
        },
        async clickPaneInterview() {
            if (this.canAppoiintment()) {
                const {data} = await Landing.getGmValidDate({pageNum:1, pageSize:9999, jobRole: this.userInfo.jobRole})
                this.columns = this.generateColumns(data)
                this.showPicker = true
            } else {
                Dialog.alert({
                    width: '95%',
                    allowHtml: true,
                    className: 'dialog-alert-font-class2',
                    messageAlign: 'left',
                    title: '非常抱歉',
                    message: '訓練營測評僅能在5月27日至6月1日進行預約。如有任何問題，請您與培訓助理進行聯繫：<br>Xiaoshuang Liu<br>xsliu@gpstrategies.com'
                })
            }
        },
        clickSignUp() {
            if (this.canSignUp()) {
                console.warn('開始進入活動報名')
                window.location.href = process.env.VUE_APP_GM_SIGNUP_URL + '&hubId=' + this.hubId + '&_locale=zh_HK'
            } else {
                Dialog.alert({
                    width: '95%',
                    allowHtml: true,
                    className: 'dialog-alert-font-class2',
                    messageAlign: 'left',
                    title: '非常抱歉',
                    message: '總經理訓練營活動報名僅在7月22日至7月26日開放。如有任何問題，請您與培訓助理進行聯繫：<br>KIMMY：13818988508<br>bmc_trainingassistant@gpstrategies.com'
                })
            }
        },
        onConfirm(value) {
            this.strAppointmentDate = value[0]
            this.strAppointmentTime = value[1]
            const data = this.groupList[value[0]].find(o => o.text == value[1])
            Landing.addGmAppointment({
                hubId: this.hubId,
                phone: this.phone,
                appointmentDate: data.appointmentDate,
                appointmentNo: data.appointmentNo,
                appointmentTime: data.appointmentTime,
            }).then(() => {
                // Toast('预约成功')
                Dialog.alert({
                    width: '95%',
                    allowHtml: true,
                    className: 'dialog-alert-font-class2',
                    messageAlign: 'left',
                    title: '預約成功',
                    message: '您的訓練營測評預約已成功。如有任何問題，請您與培訓助理進行聯繫：<br>Xiaoshuang Liu<br>xsliu@gpstrategies.com'
                })
                this.showPicker = false
                this.isAppointment = true
            }).catch(e => {
                console.warn('response', e.response)
                Toast('預約失敗')
            })
        },
        generateColumns(list) {
            this.groupList = groupBy(list.map(o => {
                return {
                    ...o,
                    dateText: moment(o.appointmentDate).format('YYYY-MM-DD'),
                    disabled: o.planCount - o.realCount <= 0,
                    text: `${o.appointmentTime} ${o.planCount - o.realCount <= 0 ? '已滿':''}`
                }
            }), 'dateText')
            return Object.keys(this.groupList).map(k => {
                return {
                    text: k,
                    children: this.groupList[k]
                }
            })
        },
        handleCancel() {
            if (this.canAppoiintment()) {
                Dialog.confirm({
                    width: '80%',
                    allowHtml: true,
                    className: 'dialog-alert-font-class-course-detail',
                    messageAlign: 'center',
                    title: '取消預約',
                    message: '確定取消預約?'
                }).then(() => {
                    Landing.deleteGmAppointment({hubId: this.hubId}).then(() => {
                        this.strAppointmentDate = ''
                        this.strAppointmentTime = ''
                        this.isAppointment = false
                        Toast('取消預約成功')
                    }).catch(() => {
                        Toast('取消預約失敗')
                    })
                }).catch(() => {
                });
            } else {
                Dialog.alert({
                    width: '95%',
                    allowHtml: true,
                    className: 'dialog-alert-font-class2',
                    messageAlign: 'left',
                    title: '非常抱歉',
                    message: '本次預約已經截止，您將無法取消預約。如有任何問題，請您與培訓助理進行聯繫：<br>Xiaoshuang Liu<br>xsliu@gpstrategies.com'
                })
            }
        },
        copyUrl() {
            let cInput = document.createElement("input");
            cInput.value = this.classUrl
            document.body.appendChild(cInput);
            cInput.select();
            document.execCommand("Copy");
            document.body.removeChild(cInput);
            Dialog.alert({
                width: '90%',
                allowHtml: true,
                className: 'dialog-alert-font-class2',
                messageAlign: 'left',
                title: '鏈接複製成功',
                message: '您的課程鏈接複製成功，請打開瀏覽器並粘貼到地址欄。推薦安裝Microsoft Teams App，並使用平板或者電腦以獲得更佳課程體驗。'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.finish-wrap{
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.logo {
    width: 375px;
    height: 80px;
    background-color: white;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    img {
        width: 84px;
        height: 42px;
        margin: 20px 20px;
    }
}
.video-box {
    display: flex;
    justify-content: center;
    padding: 0px 0px 5px;
    img {
        width: 100%;
        max-height: 180px;
        // border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.quiz-box {
    background-color: #fff;
    border-radius: 8px;
    margin: 5px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .quiz-left {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .span1 {
        color: rgba(0,0,0,0.8);
        font-size: 1.2em;
        padding: 20px 2px 10px 15px;
    }
    .span2 {
        align-items: center;
        color: rgba(0,0,0,0.7);
        font-size: 1em;
        padding: 5px 2px 15px 15px;
    }
    .quiz-right {
        width: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .quiz-arrow {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    .quiz-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .span2 {
            align-items: center;
            color: rgba(0,0,0,0.7);
            font-size: 1em;
            padding: 5px 2px 5px 15px;
        }
        .span3 {
            color: rgba(0,0,0,0.8);
            font-size: 0.8em;
            padding: 5px 2px 5px 15px;
            .span4 {
                color: rgba(0,50,32,0.7);
            }
        }
        .quiz-btn {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: space-between;
            padding: 5px 15px 15px 5px;
            .btn {
                width: 80px;
                height: 16px;
                line-height: 16px;
                font-size: 0.8em;
                border: 1px solid rgba(0,50,32,0.7);
                text-align: center;
                padding: 5px 3px;
                color: #fff;
                background-color: rgba(0,50,32,0.7);
                border-radius: 3px;
                &.color {
                    background: #999;
                }
            }
        }
    }
}
</style>